@import "../scss/variables";


/*------------------------------------------------------------------
[SkinModes-stylesheet Stylesheet]

Project        :   Admix - HTML5 Bootstrap Admin Template
Version        :   V.1
Create Date    :   05/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License



-------------------------------------------------------------------*/

/*--- BODY_STYLE --- */

.body-style {
  background-color: $white;

  .card, &.app.sidebar-mini .main-header {
    border:1px solid #e0e6f0 !important;
    box-shadow: none;
  }
   .app-sidebar {
		box-shadow: none;
		border-right: 1px solid #dee4ec;
	}
	.sidebar-scroll .main-sidebar-header {
		border-right: 1px solid #dee4ec;
	}
  .second-sidemenu {
    border-right:1px solid #e0e6f0  !important;
    border-left:1px solid #e0e6f0  !important;
    box-shadow: none;
  }

  .step-app > .step-steps > li > a {
    border:1px solid #e0e6f0  !important;
    box-shadow: none;
  }

  .horizontalMenucontainer .side-header {
    box-shadow: none;
    border-bottom: 1px solid #e1e6f1;
  }

  &.horizontal-dark .horizontal-main.hor-menu, &.horizontal-color .horizontal-main.hor-menu {
    box-shadow: none !important;
  }

  &.horizontalmenu-white.dark-theme .horizontalMenucontainer .side-header, &.horizontal-color.dark-theme .horizontalMenucontainer .side-header {
    box-shadow: none;
  }

  &.dark-theme {
    background-color: #252b3c !important;

    .card, &.app.sidebar-mini .main-header {
      border:1px solid rgb(56, 64, 84) !important;
      box-shadow: none;
    }

    .second-sidemenu {
      border-right:1px solid rgb(56, 64, 84) !important;
      border-left:1px solid rgb(56, 64, 84) !important;
      box-shadow: none;
    }

    .step-app > .step-steps > li > a {
      border:1px solid rgb(56, 64, 84) !important;
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: 991px) {
  .body-style .horizontalMenucontainer {
    .main-header.main-header-fixed {
      box-shadow: none;
    }

    .side-header {
      box-shadow: none;
      border-bottom: 0;
    }
  }
}

/*--- DARK_THEME BODY_STYLE --- */

/*--- FIRSTMENU_DARK --- */

.first-menu-dark {
  .first-sidemenu {
    background: #181e2c;
  }

  .resp-vtabs {
    .resp-tabs-list li {
      background: rgba(255, 255, 255, 0.17);
      background: rgba(255, 255, 255, 0.17);
    }

    .first-sidemenu li.active {
      background: rgb(0, 123, 255) !important;
    }
  }
}

/*--- SECONDMENU_DARK --- */

.second-menu-dark {
  .second-sidemenu {
    background: #252b3c !important;
    border-right: 1px solid #292f40;
    border-left: 1px solid #292f40 !important;
  }

  .user-profile-img img {
    box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
    border: 2px solid #e0e6f0;
  }

  .social-details h6 {
    color: $white;
  }

  .second-sidemenu .menu {
    background: #181e2c;
    border: 1px solid rgba(253, 254, 255, 0.1);
    border-radius: 6px;
  }

  .slide-item {
    &.active, &:hover, &:focus {
      color: #4a8ff3 !important;
      background: transparent;
    }

    color: $white-8;
    border-bottom: 1px dashed rgba(222, 228, 247, 0.2);
  }

  .second-sidemenu .list-group-item {
    background-color: transparent;
  }

  .side-account .acc-link:hover {
    background: transparent !important;
    color: $white-9;
  }

  .social-details .text-muted {
    color: #bbc5d8 !important;
  }

  .side-menu .slide.submenu .side-menu__item {
    color: $white-8;
    border-bottom: 1px dashed rgba(222, 228, 247, 0.2);
    background: transparent;

    &:hover {
      background: transparent !important;
      color: $white-9;
    }
  }

  .second-sidemenu .btn-light {
    border: 1px solid rgba(250, 251, 251, 0.2);
  }

  .upgrade {
    box-shadow: 12px 7px 13px -3px #0a0c13;
  }

  .side-menu .list-group a {
    color: $white-8;
  }

  .side-account li a {
    color: rgba(255, 255, 255, 0.73);
  }

  .resp-vtabs .resp-tabs-list li.resp-tab-active {
    background: rgb(0, 123, 255) !important;
  }

  .menu {
    .menu-label {
      box-shadow: 2px 3px 4.7px 0.3px rgb(37, 43, 60);
    }

    .side-menu {
      border-bottom: 1px dashed rgba(222, 228, 247, 0.1);
    }
  }

  .second-sidemenu {
    .text-purple {
      color: #9262e6 !important;
    }

    .text-primary {
      color: #4a8ff3 !important;
    }
  }

  .slide.submenu.is-expanded .side-menu__item {
    color: #4a8ff3 !important;
    background: transparent;
  }
}

.body-style.second-menu-dark .second-sidemenu {
  border-right:1px solid rgb(56, 64, 84) !important;
  border-left: 1px solid #292f40 !important;
  box-shadow: none;
}

.second-menu-dark {
  .slide.submenu.is-expanded .slide-menu {
    border-left: 1px solid #e0e6f0;
  }

  .side-menu {
    .angle {
      color: $white-8 !important;
    }

    .slide.submenu a {
      color: $white-8;
    }
  }

  .slide.submenu.is-expanded .slide-item.active:before {
    background: #dde4f6;
  }

  .side-menu {
    .slide-menu.submenu-list a:hover {
      background: transparent;
    }

    &:last-child {
      border-bottom: 0 !important;
    }
  }

  .slide.submenu.active {
    .side-menu__item, .angle {
      color: #4a8ff3 !important;
    }

    &:before {
      background: #4a8ff3;
    }
  }
}

/*--- SECONDMENU_WHITE --- */

.secondmenu-white.dark-theme {
  .second-sidemenu {
    background: $white;

    .menu {
      background: $white;
      border: 1px solid #e1e7f1;
    }
  }

  .slide-item {
    color: #191919;
    border-bottom: 1px dashed rgb(222, 228, 247);
  }

  .side-account li a {
    color: #191919;
  }

  .menu-label {
    color: #7987a1;
  }

  .menu .menu-label {
    box-shadow: 2px 3px 4.7px 0.3px rgba(199, 197, 216, 0.5);
    border-bottom: 1px solid $white;
  }

  .side-menu .list-group a {
    color: #141d2d;
  }

  .slide-item:last-child {
    border-bottom: 0 !important;
  }

  .side-menu .slide.submenu .side-menu__item {
    color: #191919;
  }

  .slide.submenu.is-expanded .slide-menu {
    border-left: 1px solid rgb(221, 228, 246);
  }
}

.sidebar-mini.dark-theme.secondmenu-white.firstmenu-white .second-sidemenu {
  border-left: 1px solid #e0e6f0;
}

.secondmenu-white.dark-theme {
  .resp-vtabs .resp-tabs-list li.resp-tab-active {
    background: $primary!important;

    .side-menu__icon {
      color: $white !important;
    }
  }

  .side-menu .slide.submenu a {
    color: #191919;
  }

  .slide-item {
    &.active, &:hover, &:focus {
      color: $primary!important;
      background: transparent;
    }

    &.active:before {
      background: $primary;
    }
  }

  .slide.submenu.is-expanded .side-menu__item {
    color: $primary!important;
  }

  .social-details h6 {
    color: #1c273c;
  }

  .side-menu .list-group-item {
    background-color: $white;
  }

  .menu .side-menu {
    border-bottom: 1px dashed rgb(222, 228, 247);
  }

  .upgrade {
    box-shadow: 0px 7px 4px -3px #141925;
  }
}

/*--- FIRSTMENU_WHITE --- */

.firstmenu-white {
  .first-sidemenu {
    background: $white !important;
  }

  .second-sidemenu {
    border-left: 1px solid #e0e6f0;
  }

  .resp-vtabs .resp-tabs-list li {
    background: #eceef5;
  }

  .side-menu__icon {
    color: #383e46;
  }

  .resp-vtabs .first-sidemenu li.active {
    background: $primary!important;

    .side-menu__icon {
      color: $white !important;
    }
  }
}

.app.sidebar-mini {
  &.firstmenu-white .first-sidemenu .fav-icon {
    &.fav-icon1 {
      display: block;
      height: 3rem;
    }

    display: none;
  }

  .fav-icon.fav-icon1 {
    display: none;
  }
}

/* =========== Horizontal-color CSS ============= */

.horizontal-color {
  .horizontal-main.hor-menu {
    background: $primary;
    box-shadow: (-8px) 12px 18px 0 rgba(25, 42, 70, 0.13);
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active, &:hover {
      color: $white;
      background: rgba(234, 235, 243, 0.15);
    }
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    color: $white;
    border-bottom-color: $white-1;
    border-right: 1px solid rgba(220, 231, 245, 0.2);
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li {
    &:first-child {
      border-left: 1px solid rgba(220, 231, 245, 0.2);
    }

    &:last-child {
      border-right: 1px solid rgba(220, 231, 245, 0.2);
    }
  }
}

.dark-mode.horizontal-color .horizontalMenu > .horizontalMenu-list > li:hover > a {
  color: $white;
  background: rgba(234, 235, 243, 0.15);
}

@media only screen and (max-width: 991px) {
  .horizontal-color {
    .horizontalMenu > .horizontalMenu-list {
      background: $primary;

      > li > {
        ul.sub-menu {
          background-color: $primary;
          margin: 0px;

          > li > a {
            color: $white-8;
          }
        }

        .horizontalMenu-click > i {
          color: $white-5;
        }

        ul.sub-menu li:hover > a {
          background-color: $primary;
          color: $white;
        }
      }
    }

    .mega-menubg {
      background: $primary!important;
      margin: 0px;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
      background: $primary!important;
      color: $white-8 !important;
    }

    .horizontalMenu > .horizontalMenu-list > li {
      > {
        ul.sub-menu > li > ul.sub-menu {
          background-color: $primary;

          > li > a {
            color: $white-9;

            &:hover {
              background-color: #0b5acd;
              color: $white;
            }
          }
        }

        .horizontal-megamenu {
          color: $white;
        }

        ul.sub-menu > li .horizontalMenu-click02 > i {
          color: $white-9;
        }
      }

      &:hover > a {
        color: $white;
        background: rgba(234, 235, 243, 0.15);
      }

      > .horizontal-megamenu .link-list li a:hover {
        color: $white !important;
      }
    }
  }

   .horizontal-color  .mega-menubg.hor-mega-menu h3 {
    color: $white;
  }
}

/* =========== Horizontal-Dark CSS ============= */

.horizontal-dark {
  .horizontal-main.hor-menu {
    background: #191c23;
    box-shadow: -8px 12px 18px 0 rgba(30, 33, 43, 0.13);
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active, &:hover {
      background: $black-3;
    }
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    color: $white;
    border-bottom-color: $white-1;
    border-right: 1px solid rgba(220, 231, 245, 0.3);
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li {
    &:first-child {
      border-left: 1px solid rgba(220, 231, 245, 0.3);
    }

    &:last-child {
      border-right: 1px solid rgba(220, 231, 245, 0.3);
    }
  }
}

@media only screen and (max-width: 991px) {
  .horizontal-dark {
    .horizontalMenu > .horizontalMenu-list {
      background: #191c23;

      > li > {
        ul.sub-menu {
          background-color: #272931;
          margin: 0px;

          > li > a {
            color: $white-8;
          }
        }

        .horizontalMenu-click > i {
          color: $white-5;
        }

        ul.sub-menu li:hover > a {
          background-color: #272931;
          color: $white;
        }
      }
    }

    .mega-menubg {
      background: #272931 !important;
      margin: 0px;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
      background: #272931 !important;
      color: $white-8 !important;
    }

    .horizontalMenu > .horizontalMenu-list > li > {
      ul.sub-menu > li > ul.sub-menu {
        background-color: #272931;

        > li > a {
          color: $white-6;

          &:hover {
            background-color: #1e2027;
            color: #4f94fb;
          }
        }
      }

      .horizontal-megamenu, ul.sub-menu > li .horizontalMenu-click02 > i {
        color: $white;
      }
    }

    &.active .horizontalMenucontainer .main-header.main-header-fixed {
      box-shadow: none;
    }

    .horizontalMenu > .horizontalMenu-list > li:hover > a {
      color: #7079f9;
      background: $black-3;
    }

    &.dark-mode .horizontalMenu h3 {
      color: $white;
    }

    .horizontalMenu > .horizontalMenu-list > li > {
      .horizontal-megamenu .link-list li a:hover {
        color: $white !important;
      }

      ul.sub-menu {
        background-color: #111418;
        margin: 0px;
      }
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li {
      > ul.sub-menu > li > a.active, &:hover .sub-icon {
        color: #4f94fb;
      }
    }

    .mega-menubg.hor-mega-menu h3 {
      color: #b9c0c8;
    }
  }
}

/* =========== Horizontal-color CSS ============= */

@media only screen and (max-width: 991px) {
  .horizontal-color.dark-theme .horizontal-main.hor-menu {
    box-shadow: (-8px) 12px 18px 0 rgba(25, 42, 70, 0.13);
  }
}

.horizontal-color.dark-theme {
  .horizontal-main.hor-menu {
    box-shadow: none;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active, &:hover {
      color: $white;
      background: rgba(234, 235, 243, 0.15);
    }
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    color: $white;
    border-bottom-color: $white-1;
    border-right: 1px solid rgba(220, 231, 245 , 0.3);
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li {
    &:first-child {
      border-left: 1px solid rgba(220, 231, 245 , 0.3);;
    }

    &:last-child {
      border-right: 1px solid rgba(220, 231, 245 , 0.3);;
    }
  }
}

.dark-mode.horizontal-color.dark-theme .horizontalMenu > .horizontalMenu-list > li:hover > a {
  color: $white;
  background: rgba(234, 235, 243, 0.15);
}

@media only screen and (max-width: 991px) {
  .horizontal-color {
    &.dark-theme {
      .horizontalMenu > .horizontalMenu-list {
        background: $primary;

        > li > {
          ul.sub-menu {
            background-color: $primary;
            margin: 0px;

            > li > a {
              color: $white-8;
            }
          }

          .horizontalMenu-click > i {
            color: $white-5;
          }

          ul.sub-menu li:hover > a {
            background-color: $primary;
            color: $white;
          }
        }
      }

      .mega-menubg {
        background: $primary!important;
        margin: 0px;
      }

      .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
        background: $primary!important;
        color: $white-8 !important;
      }

      .horizontalMenu > .horizontalMenu-list > li {
        > {
          ul.sub-menu > li > ul.sub-menu {
            background-color: $primary;

            > li > a {
              color: $white-9;

              &:hover {
                background-color: #0b5acd;
                color: $white;
              }
            }
          }

          .horizontal-megamenu {
            color: $white;
          }

          ul.sub-menu > li .horizontalMenu-click02 > i {
            color: $white-8;
          }
        }

        &:hover > a {
          color: $white;
          background: rgba(234, 235, 243, 0.15);
        }

        > .horizontal-megamenu .link-list li a:hover {
          color: $white !important;
        }
      }
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li:hover .sub-icon {
      color: $white;
      background: transparent;
    }
  }
}

.horizontalmenu-white.dark-theme {
  .horizontal-main.hor-menu {
    background: $white;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active {
    color: #285cf7;
    background: transparent;
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    color: #3b384a;
    border-right: 1px solid #dce7f5;
  }

  .horizontalMenucontainer .side-header {
    box-shadow: 7px 8px 9px -2px #b4bfd8;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li {
    &:first-child {
      border-left: 1px solid #dce7f5;
    }

    > a:hover {
      color: $primary!important;
    }
  }
}

@media (max-width: 991px) {
  .horizontalmenu-white.dark-theme {
    .horizontalMenu > .horizontalMenu-list {
      background: $white;

      > li > ul.sub-menu {
        background-color: $white;
        box-shadow: none;
        border: 0;
      }
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a.active {
      color: $primary;
    }

    .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
      > li > a {
        color: #191919;

        &:hover {
          color: $primary!important;
        }
      }

      li:hover > a {
        background-color: transparent;
        color: $primary!important;
      }

      > li {
        .horizontalMenu-click02 > i {
          color: #141821;
        }

        > ul.sub-menu {
          background-color: $white;
          box-shadow: 0 8px 16px 0 rgba(8, 13, 25, 0.4);
          border: 1px solid rgba(231, 234, 243, 0.1);

          > li > a {
            &:hover {
              color: $primary!important;
              background-color: rgba(231, 231, 231, 0.1);
            }

            color:$black-8;
          }
        }
      }
    }
  }
}
.dark-theme.secondmenu-white {
  .second-sidemenu .btn-light {
    color: #1c273c;
    background-color: transparent;
    border: 1px solid #dadee4;
  }

  .angle {
    color: rgba(0, 0, 0, 0.7) !important;
  }
}

.first-menu-dark .first-sidemenu {
  background: #181e2c !important;
}

.dark-theme.secondmenu-white .second-sidemenu {
  border-left: 1px solid $white;
}

.singlemenu-dark {
  .app-sidebar {
    color: $white;
    background: #181e2c;
    border-right: 1px solid #181e2c;
  }

  .slide.is-expanded {
    .side-menu__item {
      background: transparent !important;
    }

    a {
      color: $white;
      text-decoration: none;
    }
  }
}

.singlemenu-color {
  .app-sidebar {
    color: $white;
    background: $primary !important;
    border-right: 1px solid $primary;
  }

  .slide.is-expanded {
    .side-menu__item {
      background: transparent !important;
    }

    a {
      color: $white-5;
      text-decoration: none;
    }
  }

  .side-menu__label, .side-menu__item .angle {
    color: $white-6;
  }

  .side-menu .side-menu__icon {
    color: $white;
    background: rgba(255, 255, 255, 0.13);
  }

  .slide.is-expanded .slide-menu:before {
    background:  $white-1;
  }

  .sidebar-scroll .slide-item.active:before {
    background: $white;
  }

  .slide:hover {
    .side-menu__label, .angle {
      color: $white !important;
    }
  }

  .slide-menu a.active {
    background: transparent;
    color: $white-7 !important;
  }

  .social-details {
    h6 {
      color: $white;
    }

    a i {
      color: $white-8 !important;
    }
  }

  .app-sidebar__user .user-info .text-muted, .app-sidebar .side-item.side-item-category {
    color: $white-8 !important;
  }

  .side-menu__item.active .angle {
    color: $white;
  }

  .slide.is-expanded .side-menu__label {
    color: $white;
  }

  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      background: rgba(0, 0, 0, 0.2);
      color: $white !important;
    }
  }

  .slide.is-expanded a:hover {
    color: $white;
    text-decoration: none;
  }

  .app-sidebar__user img {
    box-shadow: 0px 5px 5px 0px #0862e4;
    border: 2px solid #9ab5dc;
  }

  .sidebar-scroll .main-sidebar-header {
    border-right: 1px solid rgba(222, 228, 236, 0.1);
    background: $primary;
    border-bottom: 1px solid rgba(222, 228, 236, 0.1);
  }

  .sub-slide.is-expanded:before {
    background: $white-1;
  }

  .app-sidebar .slide {
    &.active .side-menu__icon, &.is-expanded .side-menu__icon {
     background: rgba(0 ,0, 0 , 0.15);
     color: $white
    }
  }

  .slide-item {
    &.active, &:hover, &:focus {
      color: $white;
    }
  }

  .side-menu__item.active .side-menu__label {
    color: $white !important;

    &:hover {
      color: $white !important;
    }
  }
}

@media (min-width: 578px) {
  .singlemenu-color {
    .desktop-logo.logo-dark .main-logo.dark-theme {
      display: block !important;
    }

    &.app.sidebar-mini {
      .desktop-logo {
        &.logo-dark {
          display: block;
        }

        &.logo-light {
          display: none;
        }
      }

      &.sidenav-toggled .mobile-logo.icon-dark {
        display: block !important;
      }
    }
  }

  .singlemenu-dark.app.sidebar-mini.sidenav-toggled .mobile-logo {
    &.icon-dark {
      display: block !important;
    }

    &.icon-light {
      display: none !important;
    }
  }

  .singlemenu-color.app.sidebar-mini.sidenav-toggled {
    .mobile-logo.icon-light {
      display: none !important;
    }

    .desktop-logo.logo-dark {
      display: none;
    }
  }

  .singlemenu-dark.app.sidebar-mini.sidenav-toggled .desktop-logo.logo-dark {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open {
    &.singlemenu-color .desktop-logo.logo-dark {
      display: block !important;
    }

    &.singlemenu-dark .desktop-logo {
      &.logo-dark {
        display: block !important;
      }

      display: none !important;
    }

    &.singlemenu-color {
      .desktop-logo {
        display: none !important;
      }

      .main-logo.dark-theme {
        display: block !important;
      }
    }

    &.singlemenu-dark .main-logo.dark-theme {
      display: block !important;
    }
  }

  .singlemenu-dark.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.leftmenu-color .desktop-logo.logo-dark {
    display: block !important;
  }

  .singlemenu-color.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-light {
    display: none !important;
  }
}

.singlemenu-dark {
  .side-menu__label, .side-menu__item .angle {
    color: $white;
  }

  .side-menu .side-menu__icon {
    color: $white;
    background: rgba(255, 255, 255, 0.13);
  }

  .slide.is-expanded .slide-menu:before {
    background: rgb(57, 63, 76);
  }

  .social-details h6 {
    color: $white;
  }

  .app-sidebar__user img {
    box-shadow: 0px 5px 5px 0px rgb(0, 8, 25);
    border: 2px solid #525969;
  }

  .sidebar-scroll .main-sidebar-header {
    border-right: 1px solid rgba(222, 228, 236, 0.1);
    background: #181e2c;
    border-bottom: 1px solid rgba(222, 228, 236, 0.1);
  }

  .sub-slide.is-expanded:before {
    background: rgb(57, 63, 76);
  }
}

@media (min-width: 578px) {
  .singlemenu-dark {
    .desktop-logo.logo-dark .main-logo.dark-theme {
      display: block !important;
    }

    &.app.sidebar-mini .desktop-logo {
      &.logo-dark {
        display: block;
      }

      &.logo-light {
        display: none;
      }
    }
  }

  .app.sidebar-mini.sidenav-toggled .singlemenu .main-header.main-header-fixed.fixed-header {
    padding-left: 80px;
  }
}

.app.sidebar-mini {
  &.singlemenu-dark.sidenav-toggled.sidenav-toggled-open .side-menu__label, &.singlemenu-color.sidenav-toggled.sidenav-toggled-open .side-menu__label {
    color: $white;
  }
}

.dark-theme.singlemenu-white {
  .app-sidebar {
    background: $white;
    -webkit-box-shadow: -3px 7px 45px -3px #252b3c;
    box-shadow: -3px 7px 45px -3px #252b3c;
    border-right: 1px solid #383e4e;
  }

  .main-sidebar-header {
    background: $white;
    border-bottom: 1px solid #dee4ec;
  }

  .social-details h6 {
    color: #1c273c;
  }

  .slide.is-expanded {
    a {
      color: #191919;
    }

    .slide-menu:before {
      background: #e3e7ed;
    }
  }

  .side-menu__label {
    color: #191919;
  }

  .side-menu .side-menu__icon {
    color: #191919;
    background: #eceef5;
  }

  .side-menu__item {
    &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
      background: $primary;
      color: $white !important;
    }
  }

  .app-sidebar .angle {
    color: #353535 !important;
  }

  .sub-slide.is-expanded:before {
    background: #e3e7ed;
  }

  .app-sidebar__user img {
    box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
    border: 2px solid #ccd4e8;
  }
}

@media (min-width: 768px) {
  .dark-theme.singlemenu-white {
    &.app.sidebar-mini .desktop-logo.logo-dark {
      display: none !important;
      margin: 0 auto;
    }

    .desktop-logo.logo-dark .main-logo.dark-theme {
      display: none !important;
    }

    .main-logo, .desktop-logo.active.logo-light {
      display: block;
      margin: 0 auto;
    }

    &.app.sidebar-mini.sidenav-toggled {
      .logo-icon {
        display: block !important;
        height: 2.3rem;

        &.icon-dark {
          display: none !important;
        }
      }

      .desktop-logo {
        display: none;
      }

      &.sidenav-toggled-open .logo-icon {
        display: none !important;
      }
    }
  }
}
.dark-theme.singlemenu-color .app-sidebar .slide.active .side-menu__icon, .dark-theme.singlemenu-color.app-sidebar .slide.is-expanded .side-menu__icon {
    background: rgba(0 ,0, 0 , 0.15);
    color: $white
}
.singlemenu-color .side-menu .slide.active .side-menu__label, .singlemenu-color .side-menu .slide.active .side-menu__icon {
    color: $white;
}
.horizontal-color.dark-theme .horizontalMenucontainer .side-header{
  color:#0068ff !important;

}